.respect-app {
  /* Survival Plot */
  /* beeswarm */
  /*  iconMatrix */
  /* relativeLinear */ }
  .respect-app svg {
    background-color: #deeaf1; }
  .respect-app text {
    font-family: 'Roboto', sans-serif;
    fill: black; }
  .respect-app .gridlines line {
    stroke: white; }
  .respect-app .grid path {
    stroke-width: 0; }
  .respect-app .domain {
    stroke-opacity: 00; }
  .respect-app .axisLabel {
    font-size: 12px;
    fill: black; }
  .respect-app .tick line {
    stroke: white; }
  .respect-app .line {
    fill: none;
    stroke: transparent;
    stroke-width: 2px; }
  .respect-app .selectedLine {
    stroke-width: 4.5px;
    stroke: #2947a3; }
  .respect-app .hiddenLine {
    fill: none;
    stroke-opacity: 00;
    stroke-width: 15px; }
  .respect-app .active {
    stroke: steelblue;
    stroke-width: 3px; }
  .respect-app .sliders {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .respect-app .range-slider {
    position: relative;
    height: 60px;
    width: 250px;
    margin: 0px;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 00px;
    font-family: sans-serif; }
  .respect-app #medianSurvival {
    fill: orange; }
  .respect-app .cells path {
    fill: none;
    pointer-events: all; }
  .respect-app .selectedSurvivalTime {
    fill: blue; }
  .respect-app .cells :hover circle {
    fill: red; }
  .respect-app .iconPlain {
    opacity: 1;
    fill: orange; }
  .respect-app .iconSelected {
    fill: blue; }
  .respect-app .rect {
    fill: lightsteelblue; }
  .respect-app .label {
    font: 11px sans-serif; }
  .respect-app .hide {
    display: none; }
  .respect-app .label.selected {
    font: 12px bold; }
  .respect-app .stripLine {
    fill: none;
    stroke: #2947a3;
    stroke-width: 3px;
    opacity: 0.6; }
  .respect-app #tickLine {
    font: 0px bold;
    stroke-width: 5px; }
  .respect-app .HL {
    /* width:60px;height:60px;*/
    border-radius: 50%;
    border: solid 3px #555;
    background-color: #eed;
    box-shadow: 10px -10px black;
    -moz-box-shadow: 10px -10px black;
    -webkit-box-shadow: 10px -10px black;
    -o-box-shadow: 10px -10px black; }
