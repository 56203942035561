.algorithm-page-controls .pt-slider-progress {
  background: #85b4d2; }

.algorithm-page-controls__switches-container {
  display: flex;
  direction: row; }

.algorithm-page-controls__gender-switch-control {
  margin-bottom: 25px; }

.algorithm-page-controls__age-slider {
  margin-bottom: 50px; }

.algorithm-page-controls__exposure-slider {
  margin-bottom: 50px; }

.algorithm-page-controls__slider-title {
  margin-top: 0px; }
