.model-selector-page {
  display: flex;
  flex-direction: row;
  padding: 20px; }

.model-selector-page__model-name-container {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background-color: #fa6b94;
  text-align: center;
  padding-top: 57px;
  font-size: 20px;
  color: white;
  margin-right: 10px; }

.model-selector-page__model-name-container:hover {
  cursor: pointer;
  opacity: 0.9; }

.model-selector-page__algorithm-link {
  color: white; }

.model-selector-page__algorithm-link:hover {
  text-decoration: none;
  color: white; }
