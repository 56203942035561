@font-face {
  font-family: Bariol;
  src: url("/public/fonts/Bariol_Regular.otf"); }

@font-face {
  font-family: Bariol;
  src: url("/public/fonts/Bariol_Bold.otf");
  font-weight: bold; }

* {
  font-family: Bariol; }
