.respect-controls__graph-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.respect-controls__group-slider {
  margin-bottom: 20px; }

.respect-controls__graph-icon-container {
  padding: 5px; }

.respect-controls__graph-icon {
  height: 28px;
  width: 28px; }

.respect-controls__bee-swarm-icon {
  color: #524ea1; }
