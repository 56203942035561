/* prettier-ignore */
.algorithm-page {
  padding: 10px;
  width: 100%; }
  .algorithm-page .algorithm-page-controls {
    flex-basis: 30%; }
  .algorithm-page .ant-switch {
    background-color: #1890ff; }
  .algorithm-page .relative-risk-graph {
    width: 700px;
    height: 500px; }

.algorithm-page__name {
  margin-bottom: 50px; }

.algorithm-page__graphs-and-controls-container {
  display: flex;
  flex-direction: row; }

.algorithm-page__graphs-container {
  margin-right: 50px; }

.algorithm-page__cont-graphs-container {
  display: flex;
  flex-direction: row; }
