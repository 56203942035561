.respect {
  padding: 0 20px;
  display: flex;
  flex-direction: row; }
  .respect .respect-controls__group-slider .pt-slider-axis :first-child[style] {
    left: 5% !important; }
  .respect .respect-controls__group-slider .pt-slider-axis :last-child[style] {
    left: 95% !important; }
  .respect .group-table-card {
    overflow-y: scroll; }

.respect__visualizations {
  margin-right: 20px; }

.respect__respect-right-side-bar-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;
  height: 100vh; }

.respect__respect-controls-container {
  width: 65%;
  margin-bottom: 20px; }
