.group-table-one__type-label {
  padding: 5px;
  display: block;
  background-color: #cccccc; }

.group-table-one__predictors-container {
  padding-left: 20px; }

.group-table-one__predictor-container {
  display: flex;
  flex-direction: row; }

.group-table-one__predictor-label {
  flex-basis: 50%; }

.group-table-one__categorical-predictor-label {
  padding-left: 20px; }

.group-table-one__predictor-metric {
  text-align: right;
  flex-basis: 50%; }
