h1 {
  font-size: 22px;
  width: 80%;
  margin-left: 20px; }

h4 {
  margin-left: 20px; }

.cont-graph__circle {
  fill: #dd4f40; }

div.tooltip {
  position: fixed;
  text-align: center;
  width: 250px;
  height: auto;
  padding: 4px;
  font-size: 12px;
  background: #85b4d2;
  border: 0px;
  border-radius: 8px;
  pointer-events: none; }

div.circleAnnotate {
  position: absolute;
  width: 200;
  text-align: right; }

.sliders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.range-slider {
  position: relative;
  height: 50px;
  width: 210px;
  margin: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 50px;
  margin-left: 40px; }

.toggle {
  position: relative;
  height: 50px;
  width: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px; }

/*
.contPlots {
 
    display: -webkit-flex; /* Safari 
    display: flex;
    flex-wrap: nowrap;
}

.contPlots div {
    -webkit-flex: 1;  /* Safari 6.1+ 
    -ms-flex: 1;  /* IE 10     
    flex: 1;
}
*/
.btn-default.active {
  color: white;
  /*  background-color: steelblue;*/ }

.btn-group {
  border-radius: 25px; }

/* Formatting for Hazard plot */
#point {
  fill: lightsteelblue;
  stroke: steelblue;
  stroke-width: 4; }

.area {
  fill: lightsteelblue; }

.line {
  stroke-width: 6;
  fill: none;
  stroke: #85b4d2; }

.line2 {
  stroke-width: 3;
  fill: none;
  stroke: white; }

.title {
  font: 300 16px;
  fill: #666; }

@media (max-width: 500px) {
  body {
    width: 95%; }
  h1 {
    font-size: 17px; }
  #chart {
    width: 95%; } }

/* X-axis formatting */
/*.axis text {
    font: ;
    fill: #666;
} */
