.switch-control__label {
  margin-top: 0px; }

.switch-control__switch-container {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .switch-control__switch-container .pt-control.pt-inline {
    margin-right: 0px; }
  .switch-control__switch-container .pt-control {
    margin-bottom: 0px; }

.switch-control__current-value-label {
  margin-bottom: 2px; }
